<div *ngIf="isStart" class="wrapper">
  <header class="header">
    <div class="button-time">
      <span>{{ timer }}</span>
    </div>
  </header>
  <div
    style="
      align-items: center;
      display: flex;
      justify-content: center;
      height: calc(100vh - 40rem);
    "
  >
    <section class="questions--grid">
      <div *ngFor="let question of pagination" class="question--padding" [ngClass]="{'question--required':question.incomplete}">
        <div >
          <span class="description--questions"> {{ question.question }} </span>
        </div>
        <div class="img-sinalization">
          <img style="width: 80px;margin-left: 4px;" *ngIf="question.img" [alt]="question.img" [src]="question.img" />
        </div>
        <form>
          <div
            *ngFor="let alternative of question.alternatives; index as $index"
            class="radio--option"
            [ngClass]="{
              'radio--option-wrong':
                alternative.checked && !alternative.isRigth && isFinished,
              'radio--option-correct':
                alternative.checked && alternative.isRigth && isFinished
            }"
          >
            <input
              type="radio"
              [id]="alternative.description"
              name="alternative"
              [checked]="question.selected == alternative.id"
              (click)="selectedOpiton(alternative,question,$index)"
            /><label [for]="alternative.description">{{
              alternative.description | titlecase
            }}</label>
          </div>
        </form>
      </div>
    </section>
  </div>
  <div class="end" *ngIf="currentPage === totalPages">
    <div>
      <button class="button-simulator" (click)="finishTest()">Finalizar</button>
    </div>
  </div>
  <footer class="footer-form">
    <button class="button-simulator" (click)="backPage()">
      <span>Anterior</span>
    </button>
    <span style="color: #fff">{{ currentPage }}/{{ totalPages }}</span>
    <button class="button-simulator" (click)="nextPage()">
      <span>Proxima</span>
    </button>
  </footer>
</div>

<div *ngIf="!isStart" class="start">
  <div>
    <button class="button-simulator" (click)="start()">
      <span>Começar simulado</span>
    </button>
    <span>40 perguntas, 60 minutos para realizar</span>
  </div>
</div>

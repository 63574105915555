import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'drun-mobile-simulator-option',
  templateUrl: './mobile-simulator-option.component.html',
  styleUrls: ['./mobile-simulator-option.component.scss']
})
export class MobileSimulatorOptionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

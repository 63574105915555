<div style="z-index: 9">
  <header class="header-body" [ngClass]="{ bg: scrollAction }">
    <div class="navigation">
      <div *ngIf="innerWidth < 768" class="sideMenu">
        <!-- <drun-side-menu (sideMenu)="sideMenu.emit($event)"></drun-side-menu> -->
        <img class="header-logo-side" [src]="logo" alt="logo" />
      </div>

      <img
        *ngIf="innerWidth > 768"
        class="header-logo"
        [src]="logo"
        alt="logo"
      />

      <nav *ngIf="innerWidth > 768">
        <ul class="header-navegation" style="margin-bottom: 0 !important">
          <!-- <li><a routerLink="" class="header-item-nav">Home</a></li> -->
          <!-- <li><a routerLink="/sobre" class="header-item-nav">Sobre</a></li>
          <li>
            <a routerLink="/serviços" class="header-item-nav">Serviços</a>
          </li> -->
          <!-- <li>
            <a routerLink="/simulados" class="header-item-nav">Simulados</a>
          </li> -->
          <!-- <li>
            <a routerLink="/contato" class="header-item-nav">Contato</a>
          </li> -->
        </ul>
      </nav>
    </div>
  </header>
</div>

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'drun-direction-defensive-test',
  templateUrl: './direction-defensive-test.component.html',
  styleUrls: ['./direction-defensive-test.component.scss'],
})
export class DirectionDefensiveTestComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'drun-sinalization-test',
  templateUrl: './sinalization-test.component.html',
  styleUrls: ['./sinalization-test.component.scss'],
})
export class SinalizationTestComponent implements OnInit {
  questions = [];
  pagination: any = [];

  isStart: boolean = false;
  isFinished: boolean = false;

  minute: number = 0;
  second: number = 0;
  millisecond: number = 0;

  timer: any;

  currentPage: number = 1;

  pagePrimary = -1;
  pageSecundarie = 4;

  totalPages = 0;

  constructor() {}

  ngOnInit(): void {
    this.totalPages = Math.ceil(this.questions.length / 4);
  }

  start() {
    this.isStart = true;
    this.getPagination();

    setInterval(() => {
      this.timer = this.createTimer();
    }, 10);
  }

  createTimer() {
    if ((this.millisecond += 10) == 1000) {
      this.millisecond = 0;
      this.second++;
    }
    if (this.second == 60) {
      this.second = 0;
      this.minute++;
    }
    if (this.minute == 60) {
      this.minute = 0;
    }

    let second = this.second < 10 ? `0${this.second}` : this.second;
    let minute = this.minute < 10 ? `0${this.minute}` : this.minute;

    return `${minute}:${second}`;
  }

  selectedOpiton(alternative, question, index) {
    question.incomplete = false;
    alternative.checked = !alternative.checked;
    alternative.checked
      ? (question.selected = alternative.id)
      : (question.selected = null);

    question.alternatives.forEach((alternative, _index) =>
      _index !== index ? (alternative.checked = false) : ''
    );
  }

  getPagination() {
    if (this.pagination && this.pagination.length) {
      this.pagination.forEach((element) => {
        let index = this.questions.indexOf(element);
        if (index !== -1) {
          this.questions.splice(index, 1);
          this.questions.splice(index, 0, element);
        }
      });
    }

    this.pagination = this.questions.filter(
      (question, index) =>
        index > this.pagePrimary && index < this.pageSecundarie
    );
  }

  nextPage() {
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.pagePrimary += 4;
      this.pageSecundarie += 4;
      this.getPagination();
    }
  }

  backPage() {
    if (this.currentPage > 1) {
      console.log(this.pagePrimary);
      if (this.pagePrimary >= 3) this.pagePrimary -= 4;
      if (this.pageSecundarie >= 7) this.pageSecundarie -= 4;
      this.currentPage--;
      this.getPagination();
    }
  }

  veryflyQuestions() {
    let isOK: boolean = true;
    this.questions.forEach((question) => {
      if (!question.alternatives.some((item) => item.checked)) {
        question.incomplete = true;
        isOK = false;
      }
    });
    return isOK;
  }

  finishTest() {
    this.isFinished = false;
    let isOK = this.veryflyQuestions();

    if (isOK) this.isFinished = true;
  }
}

import { HostListener } from '@angular/core';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'drun-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Output() sideMenu = new EventEmitter<boolean>();
  logo: string = '/assets/icone1.webp';
  innerWidth: any;
  scrollAction: boolean = false;
  constructor() {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.innerWidth = event.target.innerWidth;
  }

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    if (e.target['scrollingElement'].scrollTop > 3) this.scrollAction = true;
    else this.scrollAction = false;
  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
  }

  moveToHome() {
    window.location.hash = '#homeId';
  }

  moveToAbout() {
    window.location.hash = '#aboutId';
  }

  moveToServices() {
    window.location.hash = '#servicesId';
  }

  moveToContact() {
    window.location.hash = '#contacId';
  }
}

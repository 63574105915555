<div class="grid-item">
  <div class="item">
    <span>Sinalização</span>
  </div>
  <div class="item">
    <span>Legislação</span>
  </div>
  <div class="item">
    <span>Direção defensiva</span>
  </div>
  <div class="item">
    <span>Primeiros socorros</span>
  </div>
  <div class="item">
    <span>Meio ambiente</span>
  </div>
</div>

<div class="container">
  <div>
    <div class="row">
      <div>
        <h4>Fale Conosco !</h4>
        <ul>
          <li>
            (47)997350464 <i nz-icon nzType="whats-app" nzTheme="outline"></i>
          </li>
          <li>
            autoescoladrun@gmail.com
            <i nz-icon nzType="mail" nzTheme="outline"></i>
          </li>
          <li>R. Pioneiro Andre Voltolini, 1060 - Nereu Ramos</li>
        </ul>
      </div>
      <hr />
      <div class="row">
        <p style="margin-bottom: 0">
          &copy; <span>{{ currentDate }}</span> Drun - Auto Escola | Todos os
          Direitos Reservados | Termos e Serviçoes | Privado
        </p>
      </div>
    </div>
  </div>
</div>

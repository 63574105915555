import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'drun-simulator-questions',
  templateUrl: './simulator.component.html',
  styleUrls: ['./simulator.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SimulatorComponent {
  @Input() questions: any[] = [];
  start: boolean = false;
  timer: string;

  minute: number = 0;
  second: number = 0;
  millisecond: number = 0;

  indexQuestion: number = 0;

  currentQuestions: any;
  constructor() {}

  startSimulator(): void {
    this.start = true;
    this.currentQuestions = this.questions[0];

    setInterval(() => {
      this.timer = this.createTimer();
    }, 10);
  }

  selectedOpiton(alternative, question, index) {
    question.incomplete = false;
    alternative.checked = !alternative.checked;
    alternative.checked
      ? (question.selected = alternative.id)
      : (question.selected = null);

    question.alternatives.forEach((alternative, _index) =>
      _index !== index ? (alternative.checked = false) : ''
    );
  }

  createTimer() {
    if ((this.millisecond += 10) == 1000) {
      this.millisecond = 0;
      this.second++;
    }
    if (this.second == 60) {
      this.second = 0;
      this.minute++;
    }
    if (this.minute == 60) {
      this.minute = 0;
    }

    let second = this.second < 10 ? `0${this.second}` : this.second;
    let minute = this.minute < 10 ? `0${this.minute}` : this.minute;

    return `${minute}:${second}`;
  }

  restart(): void {}
}

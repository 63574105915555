<div class="container" (click)="isOpen = true; sideMenu.emit(true)">
  <div class="line"></div>
  <div class="line"></div>
  <div class="line"></div>
</div>
<nav class="container-side">
  <nav [ngClass]="{ 'sideMenu open': isOpen, sideMenu: !isOpen }">
    <ul>
      <li (click)="isOpen = false"><a routerLink="" class="button">Home</a></li>
      <li (click)="isOpen = false">
        <a routerLink="/sobre" class="button">Sobre</a>
      </li>
      <li (click)="isOpen = false">
        <a routerLink="/serviços" class="button">Serviços</a>
      </li>
      <!-- <li (click)="isOpen = false"><a routerLink="/simulados" class="button">Simulados</a></li> -->
      <li (click)="isOpen = false">
        <a routerLink="/contato" class="button">Contato</a>
      </li>
    </ul>
  </nav>
</nav>
<div
  *ngIf="isOpen"
  (click)="isOpen = false; sideMenu.emit(false)"
  class="bg-dark"
></div>

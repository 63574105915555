import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'drun-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent implements OnInit {
  @Output() sideMenu = new EventEmitter<boolean>();

  isOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}

import { SimulatorComponentsModule } from './../componets/simulatorComponents/simulator-components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { HomeComponent } from '../pages/home/home.component';

import { InterfaceComponentsModule } from './../componets/interfaceComponents/interface-components.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  declarations: [
    HomeComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    InterfaceComponentsModule,
    SimulatorComponentsModule,
    NzIconModule,
    NzSpinModule,
  ],
  exports: [
    HomeComponent,

  ],
})
export class PageModule {}

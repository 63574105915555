import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';

import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';

import { PageModule } from './pages/page.module';
import { AppComponent } from './app.component';
import { SimulatorComponentsModule } from './componets/simulatorComponents/simulator-components.module';
import { InterfaceComponentsModule } from './componets/interfaceComponents/interface-components.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NzButtonModule,
    InterfaceComponentsModule,
    NzIconModule,
    HttpClientModule,
    PageModule,
    NzSpinModule,
    NzCardModule,
    SimulatorComponentsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

<section id="homeId" class="wrapper__home">
  <div class="w-100 flex-centered">
    <img class="img-center" src="assets/logo-branco-amarelo.webp" alt="logo" />
    <p style="font-size: 16px">Aquí teoria vira <b>prática !</b></p>
  </div>

  <div class="grid-2 gird--gap-30">
    <div>
      <h1 class="title">
        {{ "Agilidade e Velocidade" }}
      </h1>
      <p>
        Nosso foco é entregar para os nossos clientes o melhor serviço com a
        melhor velocidade possivel, para que voce possa aproveitar o quanto
        antes
      </p>
      <div class="mt-auto">
        <span>{{ "Consultar nota" }}</span>
        <form [formGroup]="inputCPF" class="d-flex gap-10 align-items-center">
          <input formControlName="cpf" type="" placeholder="CPF" />
          <button (click)="checkTest()">Consultar</button>
        </form>
        <span class="danger" *ngIf="inputCPF.touched && inputCPF.invalid">
          {{ "CPF invalido" }}
        </span>
      </div>
    </div>
    <div>
      <h1 class="title mb-0">{{ "Top 1 em aprovações " }}</h1>
      <h2 class="sub-title mb-1">
        {{ "Segundo a 15ª - Delegacia Regional de Polícia - Jaraguá do Sul" }}
      </h2>
      <p>
        Na hora de tirar sua cnh, escolha a <b>melhor!!</b>. A Autoescola DRUN
        que mais cresce e aprova, líder em crescimento, referência em ensino e
        campeã em aprovação, temos instrutores capacitados com mais de 10 anos
        de experiencia para fornecer um melhor aprendizado e tranquilidade no
        processo de aprendizagem
      </p>
    </div>
  </div>

  <div class="w-100">
    <h1 class="title">{{ "Contato e endereço" }}</h1>
    <div class="grid-2 gird--gap-30 w-100">
      <div>
        <img
          src="assets/Imagem3.webp"
          style="max-width: 100% !important; margin-bottom: 1em"
        />
        Estamos te esperando para melhor atender, de Segunda a Sexta, entre em
        contato e tire suas duvidas!.

        <div class="flex-centered w-100 ;" style="margin-top: 1em">
          <button (click)="goToPhone()">{{ "WhatsApp acesse aqui!" }}</button>
        </div>
      </div>
      <div>
        <iframe
        class="maps"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5051.786775836155!2d-49.174599201589096!3d-26.45080680848395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x605370ecc8411453!2sAutoescola%20Drun!5e0!3m2!1spt-BR!2sbr!4v1665446012488!5m2!1spt-BR!2sbr"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        R. André Voltolini, 1020 - Nereu Ramos Jaraguá do Sul - SC, 89265-700
        <div class="flex-centered w-100 ;" style="margin-top: 2em">
          <button (click)="goToGoogleMaps()">{{ "Abra o google maps aqui!" }}</button>
        </div>
      </div>
    </div>
  </div>

  <div class="grid-2 mt-80 gird--gap-30 w-100">
    <div>
      <h1 class="title">{{ "Quem somos?" }}</h1>
      <div>
        A <b>Drun</b> de hoje é o resultado de um projeto incrível que começou
        <b>anos atrás! </b> Com foco em um <b>trânsito mais seguro</b>, possui
        uma metodologia própria voltada ao <b> treinamento de habilitados</b>,
        oferecendo assim, todo o apoio e suporte necessários para uma
        <b>
          direção segura, confiante e prática.
          <br />
          <br />
          Agora
        </b>
        , toma forma também como <b>Autoescola </b>, contando com uma frota de
        <b>veículos de primeira </b> linha, <b>instrutores </b>capacitados e
        especializados, <b>estrutura própria e ampla</b>, além de
        <b>oferecer </b> toda a <b>atenção que você precisa</b> num dos momentos
        mais importantes da sua vida. Unindo o histórico de
        <b>tradição à modernidade</b>, a Drun garante a exclusividade da empresa
        no mercado jaraguaense <b>colocando você no caminho certo</b>.
      </div>
    </div>
    <div>
      <img class="img-center" src="assets/Imagem1.webp" alt="imagem" />
    </div>
  </div>

  <section class="grid-2 gird--gap-30 w-100">
    <div class="flex-centered">
      <img class="img-border" src="assets/moto-carro.webp" alt="imagem" />
    </div>
    <div class="flex-centered">
      <img class="img-border" src="assets/frent.webp" alt="imagem" />
    </div>
  </section>

  <div class="grid-2 gird--gap-30 w-100">
    <div class="flex-centered">
      <h1 class="title">{{ "Serviços" }}</h1>
      <ul>
        <li>
          <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Primeira Habilitação
        </li>
        <li>
          <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Alteração de Dados
        </li>
        <li>
          <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Renovação
        </li>
        <li>
          <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Reciclagem EAD
        </li>
        <li>
          <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Segunda Via
        </li>
        <li><i nz-icon nzType="caret-right" nzTheme="outline"></i> PID</li>
        <li>
          <i nz-icon nzType="caret-right" nzTheme="outline"></i>
          Adição de Categoria A e B
        </li>
      </ul>
    </div>
    <div>
      <h1 class="title" style="text-align: center">
        {{ "Maior pista de moto da região" }}
      </h1>
      <div>
        <img
          class="img-border"
          style="max-width: 100% !important"
          src="assets/pista-cima.webp"
          alt="imagem"
        />
      </div>
    </div>
  </div>
</section>

<section *ngIf="!start" class="w-100 h-100 flex-centered">
  <div class="start-button h-100">
    <button (click)="startSimulator()">Começar</button>
    <span>40 perguntas, 60 minutos para realizar</span>
  </div>
</section>

<section class="wrapper_simulator" *ngIf="start">
  <div class="header_simulator">
    <button (click)="restart()">Recomeçar</button>
    {{ timer }}
  </div>
  <main class="mt-2">
    <div>
      <span class="description--questions">
        {{ currentQuestions.question }}
      </span>
    </div>
    <div class="img-sinalization">
      <img
        style="width: 80px; margin-left: 4px"
        *ngIf="currentQuestions.img"
        [alt]="currentQuestions.img"
        [src]="currentQuestions.img"
      />
    </div>
    <form>
      <div
        *ngFor="
          let alternative of currentQuestions.alternatives;
          index as $index
        "
        class="radio--option"
        [ngClass]="{
          'radio--option-wrong':
            alternative.checked && !alternative.isRigth && isFinished,
          'radio--option-correct':
            alternative.checked && alternative.isRigth && isFinished
        }"
      >
        <input
          type="radio"
          [id]="alternative.description"
          name="alternative"
          [checked]="currentQuestions.selected == alternative.id"
          (click)="selectedOpiton(alternative, currentQuestions, $index)"
        /><label [for]="alternative.description">{{
          alternative.description | titlecase
        }}</label>
      </div>
    </form>
  </main>
  <div class="footer_simulator mt-2">
    <button *ngIf="indexQuestion">Anterior</button>
    <button>Proxima</button>
  </div>
</section>

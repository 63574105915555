import { AppRoutingModule } from './../../app-routing.module';
import { FooterComponent } from './footer/footer.component';
import { SideMenuComponent } from './side-menu/side-menu.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { NzIconModule } from 'ng-zorro-antd/icon';

@NgModule({
  declarations: [HeaderComponent, SideMenuComponent, FooterComponent],
  imports: [CommonModule, NzIconModule, AppRoutingModule],
  exports: [HeaderComponent, SideMenuComponent, FooterComponent],
})
export class InterfaceComponentsModule {}

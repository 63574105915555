import {
  Component,
  OnInit,
  ViewEncapsulation,
  HostListener,
} from '@angular/core';
import * as AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  isOpen: boolean = false;

  draw: string =
    'https://blush.design/api/download?shareUri=mr4h5LyaY&w=800&h=800&fm=png';

  @HostListener('window:scroll', ['$event']) onWindowScroll(e) {
    AOS.init({ duration: 1200 });
  }

  constructor() {}

  ngOnInit() {}

  eventSideMenu(event) {
    if (event) this.isOpen = event;
    else setTimeout(() => (this.isOpen = event), 300);
  }
}

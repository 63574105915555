import { SinalizationTestComponent } from './sinalization-test/sinalization-test.component';
import { DirectionDefensiveTestComponent } from './direction-defensive-test/direction-defensive-test.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileSimulatorOptionComponent } from './mobile-simulator-option/mobile-simulator-option.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { SimulatorComponent } from './simulator/simulator.component';

@NgModule({
  declarations: [
    DirectionDefensiveTestComponent,
    SinalizationTestComponent,
    MobileSimulatorOptionComponent,
    SimulatorComponent,
  ],
  imports: [CommonModule, FormsModule, NzIconModule, ReactiveFormsModule],
  exports: [
    DirectionDefensiveTestComponent,
    SinalizationTestComponent,
    MobileSimulatorOptionComponent,
    SimulatorComponent,
  ],
})
export class SimulatorComponentsModule {}
